import { storeToRefs, skipHydrate } from 'pinia'
import { EContent } from 'auth/enums/dialog-contents'
import type { AddToCartPayload } from 'checkout/types/add-to-cart-payload'
import type { CartResponse } from 'checkout/types/cart-response'
import type { Order } from '@/types/order'

export const useCartStore = defineStore('cartStore', () => {
  const { $notify } = useNuxtApp()
  const cart = ref<CartResponse | undefined>()
  const isOrderProcessing = ref(false)
  const latestOrderId = ref('')
  const errorMessage = ref('')
  const cookieCartId = useCookie('cartId')
  const isPurchasingAlreadyTriggered = ref(false)

  const addItem = async (options: AddToCartPayload) => {
    const authStore = useAuthStore()
    const { isDialogVisible, isLoggedIn } = storeToRefs(authStore)

    if (!isLoggedIn.value) {
      isDialogVisible.value = true
      authStore.switchContent(EContent.SIGN_UP_FIRST_BRAND)
      useRouter().push({
        query: { redirect: useRoute().path },
      })

      if (isPurchasingAlreadyTriggered.value) {
        return false
      }

      isPurchasingAlreadyTriggered.value = true
      await until(isLoggedIn).toBe(true)
      isPurchasingAlreadyTriggered.value = false
    }

    try {
      const { data, error } = await useApi<CartResponse>('cart', {
        method: 'post',
        body: {
          productId: options.productId,
          variantId: options.variantId,
          extra_delivery: options.extraDelivery,
          post_on_social: options.postOnSocial,
          extra_platforms: options.platforms,
        },
      })

      if (error.value) {
        throw new Error(error.value?.message)
      }

      if (data.value) {
        cart.value = data.value
        cookieCartId.value = data.value.id
      }
      return navigateTo('/checkout')
    } catch (error) {
      $notify({
        text: (error as Error).message || 'Oops, something went wrong',
        severity: 'error',
      })
    }
  }

  const fetch = async (cartId: string) => {
    try {
      isOrderProcessing.value = true
      const { data, error } = await useApi<CartResponse>(`cart/${cartId}`)

      if (error.value || !data.value) {
        throw new Error(error.value?.message)
      }

      cart.value = data.value
      cookieCartId.value = data.value.id
      return true
    } catch (error) {
      $notify({
        text: (error as Error).message || 'Failed to update cart',
        severity: 'error',
      })
      cookieCartId.value = null
    } finally {
      isOrderProcessing.value = false
    }
  }

  const placeOrder = async (): Promise<void> => {
    try {
      isOrderProcessing.value = true
      latestOrderId.value = ''

      const { data, error } = await useApi<Order>('order', {
        method: 'post',
        body: {
          id: cart.value?.id || '',
          version: cart.value?.version || 1,
        },
      })

      if (error.value || !data.value) {
        throw new Error(error.value?.message)
      }

      latestOrderId.value = data.value.id
      await navigateTo(`/checkout/payment/success`)
    } catch (error) {
      $notify({
        text: (error as Error).message || 'Failed to complete order',
        severity: 'error',
      })
    } finally {
      isOrderProcessing.value = false
    }
  }

  return {
    cart: skipHydrate(cart),
    latestOrderId: skipHydrate(latestOrderId),
    errorMessage: skipHydrate(errorMessage),
    isOrderProcessing: skipHydrate(isOrderProcessing),
    addItem,
    placeOrder,
    fetch,
  }
})
